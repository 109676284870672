<template>

	<div class="dropdown-container" :class="{absolute: this.absolute, squareCorner: this.squareCorner}">
		<div class="dropdown" :class="{'no-transition': this.noTransition, collapsed: this.currCollapsed, 'overflow-auto': isOpen}" :style="'max-height: '+parsedHeight">
			<div ref="content" :class="backgroundColorClass" class="dropdown-content">
				<slot></slot>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "AppDropdown",
	emits: ["toggled-dropdown"],
	data() {
		return {
			isOpen: !this.collapsed,
			currCollapsed: this.collapsed,
		}
	},
	props: {
		absolute: {type: Boolean, default: false},
		squareCorner: {type: Boolean, default: false},
		collapsed: {type: Boolean, default: true},
		//TODO replace "white" with color prop
		white: {type: Boolean, default: false},
		noTransition: {type: Boolean, default: false}
	},
	methods: {
		toggleDropdown(force = null) {
			if (force !== null) {
				this.currCollapsed = force;
			} else {
				this.currCollapsed = !this.currCollapsed;
			}
			if(!this.currCollapsed) {
				setTimeout(() => {
					this.isOpen = !this.currCollapsed;
					this.$emit("toggled-dropdown", this.isOpen);
				}, 300);
			} else {
				setTimeout(() => {
					this.isOpen = !this.currCollapsed;
					this.$emit("toggled-dropdown", this.isOpen);
				}, 20);
			}
		},
	},
	computed: {
		parsedHeight() {
			return this.currCollapsed && !this.isOpen ? '0' : this.maxHeight;
		},
		maxHeight() {
			return this.isOpen && !this.currCollapsed ? 'unset' : ((this.$refs.content.clientHeight || 0) + 10).toString() + 'px';
		},
		backgroundColorClass() {
			return this.white ? 'white' : '';
		},
	},
	watch: {
		collapsed() {
			this.toggleDropdown(this.collapsed);
		}
	}
}
</script>

<style lang="scss" scoped>

.dropdown-container {
	width: 100%;
	z-index: 100;
	border-radius: 13px;

	.dropdown {
		width: 100%;
		overflow-y: hidden;
		height: auto;
		max-height: 500px;

		&.collapsed {
			visibility: hidden;
			opacity: 0.4;
		}
		&:not(.no-transition) {
			transition: max-height 0.5s $transition, opacity .5s $transition .1s, visibility .3s ease;
		}

		.dropdown-content {
			@include smallScrollbar;
			margin-top: 10px;
			background-color: var(--color-alpha-dropdown-1);
			padding: 10px;
			border-radius: 13px;
			box-shadow: inset 0 2px 3px var(--color-alpha-4);

			&.white {
				background-color: transparent;
			}
		}

		&.overflow-auto {
			overflow-y: unset;
		}

	}

	&.absolute {
		position: relative;
		.dropdown {
			position: absolute;
			top: 0;
			box-shadow: 0 1px 2px var(--color-alpha-4);
			padding: 0;
			margin-top: 0;
			z-index: 1;

			.dropdown-content {
				margin: 0;
				border-radius: 0;
				box-shadow: none;
			}
		}
	}
	&.squareCorner {
		.dropdown {
			.dropdown-content {
				border-radius: 0;
				box-shadow: none;
			}
		}
	}
}

</style>